import React, { FC, useMemo } from 'react'
import LibMatchCard from '@sport1/news-styleguide/MatchCard'
import { isMobileView } from '@/helpers/pageHelper'
import { getMatchProps } from '@/components/SportEvent/Match/utils'
import { SportEventProps } from '@/components/SportEvent'
import { UseBettingType } from '@/hooks/useBetting'

type MatchProps = Pick<
    SportEventProps,
    | 'match'
    | 'spaceY'
    | 'spaceBottom'
    | 'spaceTop'
    | 'testID'
    | 'headline'
    | 'forceMobileView'
    | 'minify'
    | 'trackCardClick'
> & {
    onPressMore?: () => void
    matchHref?: string
    bettingProperties?: UseBettingType
}

const Match: FC<MatchProps> = ({
    match,
    spaceY,
    spaceBottom,
    matchHref,
    testID,
    headline,
    forceMobileView,
    minify,
    bettingProperties,
    trackCardClick,
    onPressMore,
}) => {
    const isMobile = isMobileView() // show only on client rendering
    const matchProps = useMemo(() => {
        const matchPropsWithoutTeamHrefs = getMatchProps({
            match,
            showTeamFullName: !isMobile && !forceMobileView,
        })
        /**
         * UX wants to reference the ticker, on the complete row
         * removing the teams' hrefs
         */
        if (matchPropsWithoutTeamHrefs?.homeCompetitor?.href) {
            matchPropsWithoutTeamHrefs.homeCompetitor.href = undefined
        }
        if (matchPropsWithoutTeamHrefs?.awayCompetitor?.href) {
            matchPropsWithoutTeamHrefs.awayCompetitor.href = undefined
        }
        return matchPropsWithoutTeamHrefs
    }, [match, isMobile, forceMobileView])

    return (
        <LibMatchCard
            spaceY={spaceY}
            spaceBottom={spaceBottom}
            matchProps={{
                onPressMatch: matchHref ? trackCardClick : undefined,
                ...matchProps,
                matchHref,
                onPressMore,
                minify,
                forceMobileView,
            }}
            betProps={
                bettingProperties?.uiProperties
                    ? {
                          ...bettingProperties.uiProperties,
                          onPressOdd: bettingProperties.onPressOdd,
                          onPressProvider: bettingProperties.onPressBetProvider,
                      }
                    : undefined
            }
            testID={testID}
            headlineProps={headline}
        />
    )
}

export default Match
