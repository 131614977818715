import { FC, memo } from 'react'
import { GenericComponentProps, GenericStandingsModel } from '@sport1/types/web'
import { SeasonClientModels } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PixelType } from '@sport1/news-styleguide/Types'
import Headline from '../Headline'
import ActionButtonsFooter from '../ActionButtonsFooter'
import StandingsWithDropdown from '@/components/StandingsWithDropdown'
import useFetch from '@/hooks/useFetch'
import { useNavigation } from '@/utils/navigation/Standard'

type Props = Pick<
    GenericStandingsModel & GenericComponentProps,
    'seasonUrl' | 'contentUrl' | 'componentKey'
>

const StandingsGeneric: FC<Props> = ({ seasonUrl, contentUrl, componentKey }) => {
    const { data, loading, error } = useFetch<SeasonClientModels>(seasonUrl)
    const { navigation } = useNavigation()

    return (
        <>
            <NonFlexingContainer
                backgroundColor="pearl"
                marginBottom={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-9']}
                minHeight={'100svh' as PixelType}
                testID="generic-standings-with-dropdown"
            >
                <NonFlexingContainer
                    paddingTop={['spacing-6', 'spacing-6', 'spacing-9', 'spacing-11']}
                    paddingX={['spacing-6', 'spacing-6', 'spacing-9', 'spacing-11']}
                >
                    <Headline
                        testID="standings-headline"
                        noStyle
                        component={{
                            text: navigation?.tag?.title
                                ? `${navigation?.tag?.title} Tabelle`
                                : 'Tabelle',
                        }}
                    />
                </NonFlexingContainer>
                {!loading && !error && data?.seasons && (
                    <StandingsWithDropdown
                        dropdownLabel="Saison"
                        dropdownOptions={{
                            firstValues: data.seasons.map(s => ({
                                label: s.name ?? '',
                                value: s.id ?? '',
                            })),
                            secondValues: [],
                        }}
                        testID={`${componentKey}-standings-generic`}
                        contentUrl={contentUrl}
                        originalDropdownData={data?.seasons}
                        componentType="GENERIC"
                    />
                )}
            </NonFlexingContainer>
            <ActionButtonsFooter
                navigationItems={navigation?.tag?.navigationItems?.filter(
                    e => e.value !== 'web-standings-sport'
                )}
                title={navigation?.tag?.title ?? ''}
            />
        </>
    )
}

export default memo(StandingsGeneric)
